import { useEffect, useState, useRef } from 'react';
import { jobStatusAPI } from '../../../api/jobStatusAPI';

import './completedAndToBeDeliverModal.css';

export default function CompletedAndToBeDeliverModal() {
    const didMount = useRef(false);
    const [deliverCount, setToBeDeliverTotal] = useState('...');
    const [completedDeliverDetails, setToBeDeliverTotalDetails] = useState([]);

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            jobStatusAPI.getJobDelivery().then((data) => {
                setToBeDeliverTotal(data.jobdelivery_total);
                setToBeDeliverTotalDetails(data.details);
            });
        }
    }, [])

    return (
        <>
            <button type="button" class="form-control btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalNum05">{deliverCount}</button>
            <div className='row'>
                <div class="modal fade" id="modalNum05" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg modal-xl">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Completed and to be delivered</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='row'>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-2 table-responsive-sm table-responsive-md '>
                                        <table className="table table-bordered text-start">
                                            <thead className="thead--color ">
                                                <tr>
                                                    <th className="col_W">Job No</th>
                                                    <th className="col_W">Date</th>
                                                    <th className="col_W">Patient</th>
                                                    <th className="col_W">Type</th>
                                                    <th className="col_W">Urgent</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    completedDeliverDetails.map((le, index) => (
                                                        <tr key={index}>
                                                            <td className='col_We'>{le.jobNo}</td>
                                                            <td className='col_We'>{le.jobDate}</td>
                                                            <td className='col_We'>{le.patientName}</td>
                                                            <td className='col_We'>{le.jobType}</td>
                                                            <td className='col_We'>{le.isUrgent === true ? "Yes" : "No"}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}