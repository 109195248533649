import { useEffect, useState, useRef } from 'react';
import { jobStatusAPI } from '../../../api/jobStatusAPI';

import './jobCollectionPendingModal.css';
import { Link } from 'react-router-dom';

export default function JobCollectionPendingModal() {
    const didMount = useRef(false);
    const [pendingCollection, setPendingCollection] = useState('...');
    const [Pickup, setPendingCollectionDetails] = useState([]);

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            jobStatusAPI.getPendingCollection().then((data) => {
                setPendingCollection(data.pendingPickupTotal)
                setPendingCollectionDetails(data.doctorPickupDetails)
            });
        }
    }, [])

    return (
        <>
            <button type="button" class="form-control btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalNum01">{pendingCollection}</button>

            <div className='row'>
                <div class="modal fade" id="modalNum01" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg modal-xl">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Job Collection Pending</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='row'>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-2  text-end table-responsive-sm table-responsive-md '>
                                        <table className="table table-bordered ">
                                            <thead className="thead--color ">

                                                <tr>
                                                    <th className="col_W text-start">RefNo</th>
                                                    <th className="col_W text-start">Date</th>
                                                    <th className="col_W text-start">Mode</th>
                                                    <th className="col_W">Normal Qty</th>
                                                    <th className="col_W">Urgent Qty</th>
                                                    <th className="col_W">Repeat Qty</th>
                                                    <th className="col_W">Total</th>
                                                    <th className="col_W text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Pickup.map((le, index) => (
                                                        <tr key={index}>
                                                            <td className='col_W text-start'>{le.refNo}</td>
                                                            <td className='col_W text-start'>{le.refDate}</td>
                                                            <td className='col_W text-start'>{le.pickupmeadium}</td>
                                                            <td className='col_W'>{le.normalQty}</td>
                                                            <td className='col_W'>{le.urgentQty}</td>
                                                            <td className='col_W'>{le.repeatQty}</td>
                                                            <td className='col_W'>{+le.normalQty + +le.urgentQty + +le.repeatQty}</td>
                                                            <td className="text-center">
                                                                <Link
                                                                    to={`/mainmenu/pickUp`}
                                                                    state={{
                                                                            propNormal: le.normalQty, propUrgent: le.urgentQty, propRepeat: le.repeatQty,
                                                                            propPickupMeadium: le.pickupmeadium, propUrgentReason: le.urgentReason, propPCode : le.pcode,
                                                                            propSubCusCode: le.subCusCode, propRefNo: le.refNo,
                                                                            propLocation: le.location
                                                                        }}>
                                                                    <button className="btn btn-sm btn-success" type="button" data-bs-dismiss="modal" aria-label="Close">Cancel / Amend</button>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}