import { api } from './configs/axiosConfig';

const endPoint = "/jobstatus";

export const jobStatusAPI = {
    getPendingCollection: async function () {
        const reponse = await api.request({
            url: `${endPoint}/pending-collection`,
            method: 'GET'
        });

        return reponse.data;
    },
    getCollectByCourier: async function () { // colleted by courier but not yet received by densu
        const reponse = await api.request({
            url: `${endPoint}/collect-by-courier`,
            method: 'GET'
        });

        return reponse.data;
    },
    getJobInProcess: async function () {
        const reponse = await api.request({
            url: `${endPoint}/job-in-process`,
            method: 'GET'
        });

        return reponse.data;
    },
    getJobDelivery: async function () {
        const reponse = await api.request({
            url: `${endPoint}/job-delivery`,
            method: 'GET'
        });

        return reponse.data;
    },
    getPickupReceived: async function () { // Received by densu, job not started
        const reponse = await api.request({
            url: `${endPoint}/pickup-received`,
            method: 'GET'
        });

        return reponse.data;
    }
}
