import PdfOne from '../../assets/pdf/JobCardFormat_one.pdf'
import PdfTwo from '../../assets/pdf/JobCardFormat_two.pdf'

export default function DownloadPDF() {
    const rptPreviewOne = () => {
        window.open(PdfOne);
    }

    const rptPreviewTwo = () => {
        window.open(PdfTwo);
    }

    return (
        <>
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 rounded form__body mb-0">
                    <div className="row">
                        <div className='col-2 col-sm-2 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-start'>
                        </div>
                        <div className="col-8 col-sm-8 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-center">
                            <h1 className=''>Job Card</h1>
                        </div>
                        <div className="col-2 col-sm-2 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-center"></div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2"></div>
                        <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                            <button type="button" className="form-control btn btn-primary " onClick={rptPreviewOne}>Job Card Template One</button>    
                        </div>
                        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2"></div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2"></div>
                        <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                            <button type="button" className="form-control btn btn-primary" onClick={rptPreviewTwo}>Job Card Template Two</button>
                        </div>
                        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2"></div>
                    </div>
                </div>
            </div>
        </>
    );
}