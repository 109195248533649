import { Link } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';

import './navigation.css';

import PickUp from '../../pages/pickUp'
import JobStatus from '../../pages/jobStatus'
import DownloadPDF from '../../pages/downloadPDF'

export default function Navigation({ logOut }) {

    const NavBar = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-1 col-sm-1 col-md-3 col-lg-3 col-xl-3 col-xxl-3"></div>
                        <div className="col-10 col-sm-10 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <div className="row ">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-center">
                                    <h1>Doctor App</h1>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <Link to="/mainmenu/pickUp">
                                        <button type="button" className="form-control btn btn-primary ">Pick Up</button>
                                    </Link>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <Link to="/mainmenu/jobStatus">
                                        <button type="button" className="form-control btn btn-primary">Job Status</button>
                                    </Link>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <Link to="/mainmenu/downloadPDF">
                                        <button type="button" className="form-control btn btn-primary">Download Job Card Template</button>
                                    </Link>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <button type="button" className="form-control btn btn-danger" onClick={logOut}>Sign Out</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-1 col-sm-1 col-md-3 col-lg-3 col-xl-3 col-xxl-3"></div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <Routes>
                <Route path={"*"} element={<NavBar />}></Route>
                <Route path={"/pickUp"} element={<PickUp />}></Route>
                <Route path={"/jobStatus"} element={<JobStatus />}></Route>
                <Route path={"/downloadPDF"} element={<DownloadPDF />}></Route>
            </Routes>
        </>
    );
}