// colleted by courier but not yet received by densu
import { useEffect, useState, useRef } from 'react';
import { jobStatusAPI } from '../../../api/jobStatusAPI';

import './collectByCourierModal.css';

export default function CollectByCourierModal() {
    const didMount = useRef(false);
    const [CollectByCourier, setCollectByCourierTotal] = useState('...');
    const [collectByCourier, setCollectByCourierDetails] = useState([]);

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            jobStatusAPI.getCollectByCourier().then((data) => {
                setCollectByCourierTotal(data.collectTotal);
                setCollectByCourierDetails(data.collectDetails);
            });
        }
    }, [])


    return (
        <>
            <button type="button" class="form-control btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalNum02">{CollectByCourier}</button>
            <div className='row'>
                <div class="modal fade" id="modalNum02" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg modal-xl">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Collected by Courier (Not Received by Densu)</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='row'>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-2 table-responsive-sm table-responsive-md '>
                                        <table className="table table-bordered ">
                                            <thead className="thead--color ">
                                                <tr>
                                                    <th className="col_We text-start">Driver Code</th>
                                                    <th className="col_We text-start">Collected Date</th>
                                                    <th className="col_We text-end">Normal Qty</th>
                                                    <th className="col_We text-end">Urgent Qty</th>
                                                    <th className="col_We text-end">Repeat Qty</th>
                                                    <th className="col_We text-end">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    collectByCourier.map((le, index) => (
                                                        <tr key={index}>
                                                            <td className='col_We text-start'>{le.driverCode}</td>
                                                            <td className='col_We text-start'>{le.driverColDate}</td>
                                                            <td className='col_We text-end'>{le.normalQty}</td>
                                                            <td className='col_We text-end'>{le.urgentQty}</td>
                                                            <td className='col_We text-end'>{le.repeatQty}</td>
                                                            <td className='col_We text-end'>{+le.normalQty + +le.urgentQty + +le.repeatQty}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}