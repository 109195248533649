import { useState, useEffect, useRef } from "react";
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

import { pickupAPI } from '../../api/pickupAPI';
import './pickUp.css'
import Spinner from "../../components/spinner/spinner";
import AllPCode from '../../components/allPcode';

export default function PickUp(s) {

    const loc = useLocation();

    const didMount = useRef(false);

    const [refNo, setRefNo] = useState(loc.state == null ? "" : loc.state.propRefNo);

    const [normal, setNormal] = useState(loc.state == null ? "" : loc.state.propNormal);
    const [urgent, setUrgent] = useState(loc.state == null ? "" : loc.state.propUrgent );
    const [repeat, setRepeat] = useState(loc.state == null ? "" : loc.state.propRepeat );
    const [pickupMeadium, setPickupMeadium] = useState(loc.state == null ? "" : loc.state.propPickupMeadium );
    const [urgentReason, setUrgentReason] = useState(loc.state == null ? "" : loc.state.propUrgentReason );
    const [selectedPcode, setSelectedPcode] = useState(loc.state == null ? "" : loc.state.propPCode);

    const [isProcessing, setIsProcessing] = useState(false);

    const [location, setLocation] = useState([]);

    const [subCusCode, setSubCusCode] = useState(loc.state == null ? "" : loc.state.propSubCusCode );
    const [selectedLocation, setSelectedLocation] = useState(loc.state == null ? "" : loc.state.propLocation );

    const savePickup = () => {
        if (selectedPcode.trim().length === 0) { toast.warn('Please Select a Center'); return; }
        if (+normal === 0 && +urgent === 0 && +repeat === 0) { toast.warn('Please Enter Pickup Details'); return; }
        if (selectedLocation.trim().length === 0 || subCusCode.trim().length === 0) { toast.warn('Please select pickup location'); return; }
        if (pickupMeadium.trim().length === 0) { toast.warn('Please select a Pickup Medium'); return; }

        if (window.confirm("Do you want to save ?") === false) return;

        setIsProcessing(true);

        pickupAPI.savePickup(+normal, +urgent, +repeat, pickupMeadium, urgentReason, selectedLocation, subCusCode, selectedPcode, refNo).then((data) => {
            clearState();
            toast.success("Successfully saved");
        }).
            finally(() => setIsProcessing(false));
    }

    const clearState = () => {
        setNormal('');
        setUrgent('');
        setRepeat('');
        setUrgentReason('');
        setRefNo('');
    }

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            pickupAPI.getDoctorLocation().then((data) => setLocation(data));
        }
    }, []);

    const OnChangeLocation = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const text = e.target.options[selectedIndex].text;

        setSubCusCode(e.target.value);
        setSelectedLocation(text);
    }

    const cancelPickup = () => {
        if (selectedPcode.trim().length === 0) { toast.warn('Please select a center'); return; }
        if (refNo.trim().length === 0) { toast.warn('Ref # not found'); return; }

        if (window.confirm("Do you want to cancel ?") === false) return;

        setIsProcessing(true);

        pickupAPI.cancelPickup(refNo, selectedPcode).then((data) => {
            clearState();
            toast.success("Successfully canceled");
        }).
            finally(() => setIsProcessing(false));
    }

    return (
        <>
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 rounded form__body mb-0">
                    <div className="row">
                        <div className='col-2 col-sm-2 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-start'>
                        </div>
                        <div className="col-8 col-sm-8 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-center">
                            <h1 className=''>Pick Up <sup>{refNo}</sup></h1>
                        </div>
                        <div className="col-2 col-sm-2 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-center"></div>
                    </div>
                    <div className="text-center">
                        {isProcessing === true && <Spinner />}
                    </div>
                    <form className={isProcessing === true && "visibility--hidden"}>
                        <div className="row mt-1">
                            <div className='col-2 col-sm-1 col-md-1 col-lg-2 col-xl-2 col-xxl-2'></div>
                            <div className='col-4 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 text-center d-none d-md-block d-lg-block d-xl-block d-xxl-block'>
                                <h4 className='py-2 bgN rounded rounded-3'>Center</h4>
                            </div>
                            <div className='col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 col-xxl-6'>
                                <div className="input-group input-group-lg">
                                    <AllPCode setSelectedPcode={setSelectedPcode} selectedPCode={selectedPcode} />
                                </div>
                            </div>
                            <div className='col-2 col-sm-1 col-md-1 col-lg-2 col-xl-2 col-xxl-2'></div>
                        </div>
                        <div className="row mt-1">
                            <div className='col-2 col-sm-1 col-md-1 col-lg-2 col-xl-2 col-xxl-2'></div>
                            <div className='col-4 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 text-center d-none d-md-block d-lg-block d-xl-block d-xxl-block'>
                                <h4 className='py-2 bgN rounded rounded-3'>Loc / Doctor</h4>
                            </div>
                            <div className='col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 col-xxl-6'>
                                <div className="input-group input-group-lg">
                                    <select className="form-select" value={subCusCode} onChange={(e) => OnChangeLocation(e)}>
                                        <option value=""></option>
                                        {
                                            location.map((data) => (
                                                <option key={data.location} value={data.subCusCode}>{data.location}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='col-2 col-sm-1 col-md-1 col-lg-2 col-xl-2 col-xxl-2'></div>
                        </div>
                        <div className="row mt-1">
                            <div className='col-2 col-sm-1 col-md-1 col-lg-2 col-xl-2 col-xxl-2'></div>
                            <div className='col-4 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 text-center'>
                                <h4 className='py-2 bgN rounded rounded-3'>Normal</h4>
                            </div>
                            <div className="col-4 col-sm-8 col-md-8 col-lg-6 col-xl-6 col-xxl-6 text-center">
                                <div className="input-group input-group-lg">
                                    <input type="number" className="form-control" value={normal} onChange={(e) => setNormal(+e.target.value)} />
                                </div>
                            </div>
                            <div className='col-2 col-sm-1 col-md-1 col-lg-2 col-xl-2 col-xxl-2'></div>
                        </div>
                        <div className="row mt-1">
                            <div className='col-2 col-sm-1 col-md-1 col-lg-2 col-xl-2 col-xxl-2'></div>
                            <div className='col-4 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 text-center'>
                                <h4 className='py-2 bgU rounded rounded-3'>Urgent</h4>
                            </div>
                            <div className="col-4 col-sm-8 col-md-8 col-lg-6 col-xl-6 col-xxl-6 text-center">
                                <div className="input-group input-group-lg">
                                    <input type="number" className="form-control" data-bs-toggle="collapse" href="#collapseExample" role="button"
                                        aria-expanded="false" aria-controls="collapseExample" value={urgent} onChange={(e) => setUrgent(+e.target.value)} />
                                </div>
                                <div className="collapse mt-1" id="collapseExample">
                                    <div className="form-outline">
                                        <textarea className="form-control" id="textAreaExample1" rows="4" placeholder="Please Enter Reason" value={urgentReason}
                                            onChange={(e) => setUrgentReason(e.target.value)} maxLength="200" />
                                    </div>
                                </div>
                            </div>
                            <div className='col-2 col-sm-1 col-md-1 col-lg-2 col-xl-2 col-xxl-2'></div>
                        </div>
                        <div className="row mt-1">
                            <div className='col-2 col-sm-1 col-md-1 col-lg-2 col-xl-2 col-xxl-2'></div>
                            <div className='col-4 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 text-center'>
                                <h4 className='py-2 bgR rounded rounded-3'>Repeat</h4>
                            </div>
                            <div className="col-4 col-sm-8 col-md-8 col-lg-6 col-xl-6 col-xxl-6 text-center">
                                <div className="input-group input-group-lg">
                                    <input type="number" className="form-control" value={repeat} onChange={(e) => setRepeat(e.target.value)} />
                                </div>
                            </div>
                            <div className='col-2 col-sm-1 col-md-1 col-lg-2 col-xl-2 col-xxl-2'></div>
                        </div>
                        <div className="row mt-1">
                            <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                <div className="input-group input-group-lg d-flex justify-content-end me-5">
                                    <div class="form-check checkName">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="ByDensu"
                                            onChange={(e) => setPickupMeadium(e.target.value)} checked={pickupMeadium === "ByDensu" ? true: false} />
                                        <label class="form-check-label" for="flexRadioDefault1">By Densu</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div className="input-group input-group-lg d-flex justify-content-start ms-5">
                                    <div class="form-check checkName">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="Other"
                                            onChange={(e) => setPickupMeadium(e.target.value)} checked={pickupMeadium === "Other" ? true : false}/>
                                        <label class="form-check-label" for="flexRadioDefault1"> Other</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className='col-0 col-sm-0 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-center'></div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 text-center">
                                <button type="button" class="btn btn-success form-control" onClick={savePickup}>Submit</button>
                                {
                                    refNo.length !== 0 &&
                                        <button type="button" class="btn btn-danger form-control mt-1" onClick={cancelPickup}>Cancel</button>
                                }
                            </div>
                            <div className="col-0 col-sm-0 col-md-2 col-lg-2 col-xl-2 col-xxl-2 text-center"></div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
