import JobCollectionPendingModal from '../../components/modal/jobCollectionPendingModal'
import CollectByCourierModal from '../../components/modal/collectByCourierModal'
import JobInProcessModal from '../../components/modal/jobInProcessModal'
import CompletedAndToBeDeliverModal from '../../components/modal/completedAndToBeDeliverModal'
import ReceiveByDensuModal from '../../components/modal/receiveByDensuModal'

export default function JobStatus() {
    return (
        <>
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 rounded form__body">
                    <div className="row">
                        <div className='col-2 col-sm-2 col-md-4 col-lg-4 col-xl-4 col-xxl-4  text-start'>
                        </div>
                        <div className="col-8 col-sm-8 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-center fw-bold">
                            <h1 className=''>Job Status</h1>
                        </div>
                        <div className="col-2 col-sm-2 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-center"></div>
                    </div>
                    <div className="row mt-3">
                        <div className='col-0 col-sm-0 col-md-2 col-lg-2 col-xl-2 col-xxl-2 text-start'></div>
                        <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1 text-start '>
                            <h6 className='txtColor rounded rounded-3'>01</h6>
                        </div>
                        <div className='col-7 col-sm-7 col-md-6 col-lg-6 col-xl-6 col-xxl-6 text-start'>
                            <h6 className='txtColor rounded rounded-3'>Job Collection Pending</h6>
                        </div>
                        <div className="col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-2 text-center">
                            <JobCollectionPendingModal />
                        </div>
                        <div className="col-0 col-sm-0 col-md-0 col-lg-1 col-xl-1 col-xxl-1 text-center"></div>
                    </div>
                    <div className="row mt-1">
                        <div className='col-0 col-sm-0 col-md-2 col-lg-2 col-xl-2 col-xxl-2 text-start'></div>
                        <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1 text-start'>
                            <h6 className='txtColor rounded rounded-3'>02</h6>
                        </div>
                        <div className='col-7 col-sm-7 col-md-6 col-lg-6 col-xl-6 col-xxl-6 text-start'>
                            <h6 className='txtColor rounded rounded-3'>Collected by Courier</h6>
                        </div>
                        <div className="col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-2 text-center">
                            <CollectByCourierModal />
                        </div>
                        <div className="col-0 col-sm-0 col-md-0 col-lg-1 col-xl-1 col-xxl-1 text-center"></div>
                    </div>
                    <div className="row mt-1">
                        <div className='col-0 col-sm-0 col-md-2 col-lg-2 col-xl-2 col-xxl-2 text-start'></div>
                        <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1 text-start'>
                            <h6 className='txtColor rounded rounded-3'>03</h6>
                        </div>
                        <div className='col-7 col-sm-7 col-md-6 col-lg-6 col-xl-6 col-xxl-6 text-start'>
                            <h6 className='txtColor rounded rounded-3'>Received by Densu</h6>
                        </div>
                        <div className="col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-2 text-center">
                            <ReceiveByDensuModal />
                        </div>
                        <div className="col-0 col-sm-0 col-md-0 col-lg-1 col-xl-1 col-xxl-1 text-center"></div>
                    </div>
                    <div className="row mt-1">
                        <div className='col-0 col-sm-0 col-md-2 col-lg-2 col-xl-2 col-xxl-2 text-start'></div>
                        <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1 text-start'>
                            <h6 className='txtColor rounded rounded-3'>04</h6>
                        </div>
                        <div className='col-7 col-sm-7 col-md-6 col-lg-6 col-xl-6 col-xxl-6 text-start'>
                            <h6 className='txtColor rounded rounded-3'>Job in Process</h6>
                        </div>
                        <div className="col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-2 text-center">
                            <JobInProcessModal />
                        </div>
                        <div className="col-0 col-sm-0 col-md-0 col-lg-1 col-xl-1 col-xxl-1 text-center"></div>
                    </div>
                    <div className="row mt-1">
                        <div className='col-0 col-sm-0 col-md-2 col-lg-2 col-xl-2 col-xxl-2 text-start'></div>
                        <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1 text-start'>
                            <h6 className='txtColor rounded rounded-3'>05</h6>
                        </div>
                        <div className='col-7 col-sm-7 col-md-6 col-lg-6 col-xl-6 col-xxl-6 text-start'>
                            <h6 className='txtColor rounded rounded-3'>Completed and to be Delivered</h6>
                        </div>
                        <div className="col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-2 text-center">
                            <CompletedAndToBeDeliverModal />
                        </div>
                        <div className="col-0 col-sm-0 col-md-0 col-lg-1 col-xl-1 col-xxl-1 text-center"></div>
                    </div>
                </div>
            </div>
        </>
    );
}
