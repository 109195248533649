import { api } from './configs/axiosConfig';

const endPoint = "/pickup";

export const pickupAPI = {
    savePickup: async function (normal, urgent, repeat, pickupMeadium, urgentReason, location, subCusCode,pcode, refNo) {
        const response = await api.request({
            url: `${endPoint}`,
            method: 'POST',
            data: { normal, urgent, repeat, pickupMeadium, urgentReason, location, subCusCode, pcode, refNo }
        });

        return response.data;
    },
    getDoctorLocation: async function () {
        const reponse = await api.request({
            url: `${endPoint}/doctor-locations`,
            method: 'GET'
        });

        return reponse.data;
    },
    cancelPickup: async function (refNo, pcode) {
        const response = await api.request({
            url: `${endPoint}/cancel`,
            method: 'POST',
            data: { refNo, pcode  }
        });

        return response.data;
    }
}
   